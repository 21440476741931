import React from 'react';
import kupol_1 from '../../images/services-images/kupol_1.jpg'

const Kupol = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Комплекс борьбы с БПЛА коммерческого типа «Купол»</h1>
                        <img src={kupol_1} alt='kupol'/>
                        <p>Изделие РЭП «Купол» предназначе­но для создания пространственных зон безопасности от беспилотных летатель­ных аппаратов (КомБПЛА) коммерче­ского типа, путем постановки помех в диапазонах частот каналов управления (2,4 ГГц) и навигации (GPS/ГЛОНАСС/ GaLileo/Beidou - 1,6 ГГц) КомБПЛА, ли­шая возможности определения своего местоположения и получения команд управления от оператора.</p>
                        <p>Для формирования больших зон без­опасности от КомБПЛА производится развертывание нескольких устройств на территории объекта с объединением в единую локальную вычислительную сеть и, в случае необходимости, организаци­ей централизованного управления с ав­томатизированного рабочего места опе­ратора.</p>
                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Максимальную выходную мощность помехового сигнала в каждом диапазоне - не менее 5 Вт;</p>
                            <p data-number="2">Возможность постановки помех в диапазонах частот: 1600/2400/5800 М Гц;</p>
                            <p data-number="3">Максимальное количество блокируемых диапазонов - 2;</p>
                            <p data-number="4">Автономный запуск и работу без ЭВМ;</p>
                            <p data-number="5">Глубину дискретной регулировки уровня выходного сигнала - 10 дБ;</p>
                            <p data-number="6">Выбор режима помехового воздействия на КомБПЛА;</p>
                            <p data-number="7">Выбор типа используемых антенн с различными диаграммами направленности (круговые и направленные) в мобильном исполнении изделия;</p>
                            <p data-number="8">Крулосуточный режим работы (при питании от промышленной сети);</p>
                            <p data-number="9">Продолжительность непрерывной работы до 4 часов от встроенной сменной АКБ в мобильном исполнении изделия;</p>
                            <p data-number="10">Управление режимом функционирования изделия с автоматизированного рабо-чего места оператора, на панели устройства или с проводного пульта дистанци-онного управления, в зависимости от варианта исполнения изделия;</p>
                            <p data-number="11">Радиус действия до 1000 метров.</p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное (в кейсе с АКБ);</p>
                            <p data-number="2">Стационарное;</p>
                            <p data-number="3">Всепогодное.</p>  
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от + 5°С до + 45°С (кроме всепогодного исполнения); от - 40°С до + 45°С </p>
                            <p data-number="2">(всепогодное исполнение).</p>
                        </div>
                        <h2>Электропитание:</h2>
                        <p>Осуществляется от однофазной промышленной сети напряжением 220 В и частотой 50 Гц и дополнительно от встроенной сменной АКБ в мобильном исполнении. Потребляемая мощность не более 150 Вт (250 Вт - всепогодное исполнение).</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kupol;
