import React, { useRef, useEffect, useState } from 'react';
import './Partners.css';

const logos = [
    { src: require('../../images/partners/cisco.png'), link: 'https://www.cisco.com/' },
    { src: require('../../images/partners/hikvision.png'), link: 'https://www.hikvision.com/en/' },
    { src: require('../../images/partners/pelco.png'), link: 'https://www.pelco.com/' },
    { src: require('../../images/partners/milestone.png'), link: 'https://www.milestonesys.com/' },
    { src: require('../../images/partners/asia.png'), link: 'https://asia-soft.kz/' },
    { src: require('../../images/partners/avigilon.png'), link: 'https://www.avigilon.com/ru' },
    { src: require('../../images/partners/balt.png'), link: 'https://baltinfocom.ru/' },
    { src: require('../../images/partners/briefcam.png'), link: 'https://www.briefcam.com/' },
    { src: require('../../images/partners/logo_basic.png'), link: 'https://specinteh.com.ua/' },
    { src: require('../../images/partners/hanwa.png'), link: 'https://www.hanwhavision.com/ru/' },
    { src: require('../../images/partners/hewlett.png'), link: 'https://www.hpe.com/us/en/home.html' },
    { src: require('../../images/partners/hytera.png'), link: 'https://www.hytera.com/ru/home.html' },
    { src: require('../../images/partners/infowatch.png'), link: 'https://www.infowatch.ru/' },
    { src: require('../../images/partners/intant.png'), link: 'https://intant.kz/' },
    { src: require('../../images/partners/irisity.png'), link: 'https://irisity.com/' },
    { src: require('../../images/partners/kodos.png'), link: 'https://kodos.ru/' },
    { src: require('../../images/partners/macroscop.png'), link: 'https://macroscop.com/' },
    { src: require('../../images/partners/magos.png'), link: 'https://magossystems.com/' },
    { src: require('../../images/partners/motorola.png'), link: 'https://www.motorolasolutions.com/ru_ru.html' },
    { src: require('../../images/partners/parsec.png'), link: 'https://www.parsec.ru/' },
    { src: require('../../images/partners/polycom.png'), link: 'https://polycom-moscow.ru/' },
    { src: require('../../images/partners/rajant.png'), link: 'https://rajant.com/' },
    { src: require('../../images/partners/set.png'), link: 'https://www.set-1.ru/' },
    { src: require('../../images/partners/logo.png'), link: 'https://taip.su/' },
    { src: require('../../images/partners/logo-infinet.png'), link: 'https://infinetwireless.com/' },
];

const Partners = () => {
    const carouselRef = useRef(null);
    const [isHovered, setIsHovered] = useState(false);
    const [scrollAmount, setScrollAmount] = useState(0);
    const scrollStep = 1;
    const scrollSpeed = 15

    useEffect(() => {
        const carouselWidth = carouselRef.current.scrollWidth / 2;

        const interval = setInterval(() => {
            if (!isHovered) {
                setScrollAmount((prevScrollAmount) => {
                    const newScrollAmount = prevScrollAmount + scrollStep;


                    if (newScrollAmount >= carouselWidth) {
                        return 0;
                    }

                    return newScrollAmount;
                });
            }
        }, scrollSpeed);

        return () => clearInterval(interval);
    }, [isHovered, scrollStep]);

    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const handlePrev = () => {
        setScrollAmount((prev) => Math.max(prev - 100, 0));
    };

    const handleNext = () => {
        setScrollAmount((prev) => prev + 100);
    };

    return (
        <div className='partners-cont'>
            <div className='section-title'><h1>НАШИ ПАРТНЕРЫ</h1></div>
            <div className="partners-content">
                <div
                    className="partner-carousel"
                    ref={carouselRef}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        transform: `translateX(-${scrollAmount}px)`,
                        transition: isHovered ? 'transform 0.5s ease-in-out' : 'transform 0.1s linear',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {logos.concat(logos).map((logo, index) => (
                        <a
                            href={logo.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={index}
                            className="partner-logo"
                        >
                            <img src={logo.src} alt={`Partner ${index + 1}`} />
                        </a>
                    ))}
                </div>
                <button className="carousel-button prev" onClick={handlePrev}>❮</button>
                <button className="carousel-button next" onClick={handleNext}>❯</button>
            </div>
            <div className='become-partner'>
                <div class="become-partner-form">
                    <form>
                        <h1>СТАТЬ ПАРТНЕРОМ</h1>
                        <div class="name-phone">
                            <div class="input-group">
                                <label>Имя</label>
                                <input type="text" placeholder="Ваше имя" required />
                            </div>
                            <div class="input-group">
                                <label>Номер телефона</label>
                                <input type="tel" placeholder="Ваш телефон" required />
                            </div>
                        </div>
                        <div class="input-group">
                            <label>Email адрес</label>
                            <input type="email" placeholder="Ваш email" required />
                        </div>
                        <div className='input-text'>
                            <textarea placeholder='Комментарий'></textarea>
                        </div>
                        <input type="submit" value="Отправить" />
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Partners;
