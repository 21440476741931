import React from 'react';
import sled_1_0 from '../../images/services-images/sled_1_0.jpg'

const Sled = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Cистема обнаружения БПЛА коммерческого типа «След-1»</h1>
                        <img src={sled_1_0} alt='sled'/>
                        <p>СО «След-1» предназначена для авто­матического обнаружения и иденти­фикации радиосигналов коммерческих беспилотных летательных аппаратов (КомБПЛА), автоматической активации блоков радиоэлектронного подавления (РЭП) «Купол», а также передачи сигнала тревоги на пост охраны. Система являет­ся полностью автоматизированной и не требует участия оператора.</p>
                        <p>Для формирования больших зон без­опасности от КомБПЛА производится развертывание нескольких устройств на территории объекта с объединением в единую локальную вычислительную сеть и, в случае необходимости, организаци­ей централизованного управления с ав­томатизированного рабочего места опе­ратора.​​​ </p>
                        <h2>СО «След-1» обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Обнаружение радиосигналов канала управления КомБПЛА (радиолиния Пульт управления - КомБПЛА) в диапазонах частот 433/ 868/2400 МГц;</p>
                            <p data-number="2">Обнаружение радиосигналов передачи данных КомБПЛА (радиолиния КомБПЛА - Пульт управления) в диапазонах частот 433/ 868/2400 М Гц;</p>
                            <p data-number="3">Определение идентификационного номера КомБПЛА (при передаче по каналу управления в открытом виде).</p>
                        </div>
                        <h2>Система состоит из:</h2>
                        <p>АПО и ПУИСТ объедин яются в единую локальную вычислительную сеть (ЛВС). Для обеспечения возможности блокирования сигналов управления и навигации БЛА, к ЛВС могут быть подключены блоки РЭП «Купол». Совместное использование си­стемы обнаружения «След» и РЭП «Купол» позволяет осуществлять автоматиче­скую активацию части или всех блоков РЭП при обнаружении КомБПЛА-нарушителя.</p>
                        <div className='spisok'>
                            <p data-number="1">Одного и более автоматических постов охраны (АПО);</p>
                            <p data-number="2">Пульта управления и сигнализации тревоги (ПУИСТ).</p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное (в кейсе с АКБ);</p>
                            <p data-number="2">Стационарное;</p>
                            <p data-number="3">Всепогодное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от + 5°С до + 45°С (кроме всепогодного исполнения);</p>
                            <p data-number="2">от - 40°С до + 45°С (всепогодное исполнение).</p>
                        </div>
                        <h2>Электропитание:</h2>
                        <p>Осуществляется от однофазной про­мышленной сети напряжением 220 В и частотой 50 Гц и дополнительно от встроенной сменной АКБ в мобиль­ном исполнении. Потребляемая мощ­ность не более 80 Вт (200 Вт - все­погодное исполнение).</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sled;
