import React from 'react';

const Ptu = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>ПТУ шлагбаумного типа</h1>
                       
                        <p>Terra Beam, Terra Road Closer Terra Beam, Terra Road Closer7,5T@80KM/4 (=30т@Д0км/ч):</p>
                        <div className='spisok'>
                            <p data-number="1">Откатное заградительное устройство Terra Beam. Изготавливаются на базе откатных ворот Terra Gate.</p>
                            <p data-number="2">Барьер поворотного типа Terra Road Closer</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Ptu;
