import React from 'react';

const Vorota = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Противотаранные ворота</h1>
                        <p>Terra Gate</p>
                        <p>Сверхмощные откатные ворота Terra Gate.
                        Успешно прошли независимые краш-тесты как при закрытом, так и наполовину открытом состоянии ворот. После таранных ударов сохраняют работоспособность.</p>
                        <p>Terra V Gate</p>
                        <p>Сверхмощные распашные ворота Terra V Gate Одобрено PSSA (Perimeter Security Suppliers Association) - ассоциацией поставщиков средств обеспечения безопасности периметра.</p>
                        <p>Terra Sliding С Gate</p>
                        <p>Консольные ворота Terra Sliding С Gate.
                        Также сертифицированы по европейскому стандарту CEN CWA 16221.</p>
                        <p>Отвечают стандартам PAS 68</p>
                        <p>Terra Sliding C Gate, Terra Gate, Terra V Gate, Terra Hinged Gate</p>
                        <p>Terra Hinged Gate</p>
                        <p>Распашные ворота Terra Hinged Gate</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Vorota;
