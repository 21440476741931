import React, { useEffect } from 'react';
import cifrovoj_fon_nachalo_starnicy from '../../images/services-images/cifrovoj_fon_nachalo_starnicy.jpg'
const Forensic = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Цифровая криминалистка</h1>
                        <img src={cifrovoj_fon_nachalo_starnicy} alt=''/>
                        <p>Компания АскомМЕТ является официальным партнёром ведущих мировых компаний по цифровой криминалистке.</p>
                        <div className='forensic-button'>
                            <input type='button' value='Связаться со специалистом'/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Forensic;
