import React, { useEffect } from 'react';
import ksb from '../../images/services-images/ksb.jpg'
const IntegratedSecuritySystem = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Комплексная система безопасности</h1>
                        <img src={ksb} alt='ksb' />
                        <p>ТОО «АскомМЕТ» осуществляет проектирование, поставку, монтаж и инсталляцию комплексных систем безопасности от отечественных и мировых производителей, а также интегрирование новейших современных систем в уже имеющейся комплексы безопасности.</p>
                        <h2>VideoXpertTM Professional</h2>
                        <p>Система видеонаблюдения на открытой платформе с интегрированным модулем распознавания лиц, поиска лиц Id-Me, видеоаналитики BriefCam и многое другое. Наличие инструмента разработчиков программного обеспечения VideoXpert SDK.</p>
                        <h2>WISENET</h2>
                        <p>Cпециальный бренд компании Hanwha Techwin для продукции, связанной с обеспечением безопасности. Он воплощает собой дух построения безопасного и взаимосвязанного мира.</p>
                        <h2>Milestone Systems</h2>
                        <p>Глобальный лидер, разработчик VMS (video management software) на   базе открытой платформы управления IP видеонаблюдением Milestone XProtect® Essential+, Express+, Professional+, Expert и Corporate с интегрированными функциями распознавания номеров автотранспорта LPR и другими опциями.</p>
                        <p>Многочисленные интегрированные решения по видеоаналитики, включая распознавание лиц, таких как FindFace Secrurity (Ntech Lab), Визирь (ЦРТ), NEC, Herta, Imagus, BriefCam, Agent Vi, IBM Intelligent Video Analytics, системы периметральной безопасности стратегически важных объектов для обеспечения антитеррористической защиты объектов, таких как аэропорты, заводы, водные сооружения, склады - решение от MAGOS и многие другие, не ограничиваясь. Важным аспектом открытой платформы XProtect® для интеграции является наличие комплекта разработки программного обеспечения Milestone Integration Platform (MIP SDK).</p>
                        <h2>BriefCam</h2>
                        <p>В программном обеспечении BriefCam Video Content Analytics используются передовые технологии компьютерного зрения и глубокого обучения, которые позволяют интеллектуально сокращать часы записанного видеоматериала всего за несколько минут, представляя только элементы, представляющие интерес для оператора.</p>
                        <p>Полученный в результате синопсис видеоклип можно легко отфильтровать с помощью мощного пользовательского интерфейса BriefCam Video Content Analytics, чтобы показать только объекты определенного класса (например, определенные типы транспортных средств, или только мужчин или женщин), а также фильтр по цвету, размеру, направлению или скорости движения, позволяющее гораздо быстрее достичь цели.</p>
                        <p>Результаты могут быть связаны с исходными кадрами в BriefCam Video Content Analytics и экспортированы оттуда для дальнейшего использования. Кроме того, программное обеспечение может представлять тепловые карты, показывающие области активности и неактивности (пребывания) субъектов и/или объектов, а также с указанием всех распространенных или заданных путей перемещения.</p>
                        <h3>ПРЕИМУЩЕСТВА РЕШЕНИЯ BriefCam:</h3>
                        <div className='spisok'>
                            <p data-number="1">Поиск виновников бостонского теракта увенчался успехом благодаря использованию уникальной израильской технологии BriefCam. Она позволяет практически мгновенно анализировать огромное количество данных, просматривая часы видеосъемки и отбирая самое важное.</p>
                            <p data-number="2">В Норвегии она использовалась для поимки Андерса Брейвика, ответственного за самый крупный теракт в Европе. Кроме США и Норвегии, технологии BriefCam активно применяются в разных государствах мира, включая Китай, Тайвань и другие.</p>
                            <p data-number="3">Video Synopsis — технология обработки изображений для создания краткой версии оригинальной полной видеозаписи. Позволяет полностью представить все события, случившиеся в часы, запечатленные на видеозаписи, в сжатом клипе длиной в несколько минут. Синопсис одновременно представляет огромное количество объектов и действий, происходивших в разное время, с индексом, отсылающим к оригинальному видеоисточнику; видеоматериалам из онлайн источников и офф-лайн архивов — для немедленного отслеживания событий, и для криминалистики в целях обнаружения доказательств. Система Video Synopsis делает возможным полный обзор видеозаписей и делает просмотр событий составной частью обеспечения безопасности.</p>
                            <p data-number="4">Системы BriefCam способны одновременно индексировать множество действий полученных с разных источников и предоставлять оператору мгновенный доступ к любому событию. Уникальность решения Video Synopsis состоит в том, что оно не является аналитическим продуктом и предназначено для немедленного обнаружения тех или иных событий в общем потоке информации.</p>
                            <p data-number="5">Решения BriefCam одинаково хорошо справляются с информацией, полученной из онлайн-источников (камер видеонаблюдения), оффлайн-архивов (видерекордеров и регистраторов) и востребованы в оперативно-розыскных действиях, криминалистике, следствии и других сферах, требующих немедленного отслеживания событий, обнаружения доказательств и т.п.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default IntegratedSecuritySystem;
