import React from 'react';

const Barriers = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Противотаранные шлагбаумы</h1>
                        <h2>Отвечают стандартам PAS 68:</h2>
                        <p>Compact Terra</p>
                        <p>Автоматический шлагбаум Compact Terra 3,5т@48км/ч (~20т@20км/ч)</p>
                        <p>Terra</p>
                        <p>Автоматический шлагбаум Terra 7,5т@65км/ч (=20т@Д0км/ч)</p>
                        <p>Terra Ultimate</p>
                        <p>Автоматический шлагбаум Terra Ultimate 7,5т@80км/ч (=20т@50км/ч или 30т@40км/ч)</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Barriers;
