import React, { useEffect } from 'react';
import secur from "../../images/services-images/secur.jpg"
const Okhrannayasignalizatsiya = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Охранная сигнализация</h1>
                        <img src={secur} alt='el_set' />
                        <p>Для обеспечения безопасности личного имущества необходимо сформировать его эффективную и надёжную защиту. Современные системы безопасности чувствительны к нескольким типам угроз: вторжению посторонних, возгоранию, утечке газа и протечке воды. Считается, что монтаж системы охраны важен для обеспечения безопасности банков, магазинов, а также других организаций, которым для осуществления своей деятельности приходится заниматься хранением различных материальных ценностей. Вовремя установленная охранная сигнализация позволит сохранить неприкосновенность вашего имущества и обеспечит необходимую степень безопасности для обитателей дома. Конфигурация охранного оборудования должна определяться в индивидуальном порядке, в зависимости от пожеланий заказчика и исходя из необходимости формирования оптимальной охранной системы.</p>


                        <h2>Системы охраны</h2>
                        <p>Системы охраны, в зависимости от способа передачи данных, подразделяются на беспроводные, проводные и GSM сигнализации. Охранная сигнализация проводного типа оповещает об опасности центральный пульт посредством проводов, а беспроводная - с помощью передачи позывных в виде радиосигнала. Но последнее оборудование подвержено воздействию погодных факторов и поэтому не является самым надёжным. Беспроводные охранные системы применяются в тех случаях, когда нет возможности соединиться с сервером с помощью проводов.</p>
                        <p>GSM сигнализации применяют для передачи данных сети сотовой связи, сигнал по которым поступает в виде SMS- сообщений. Благодаря этому, функционирование подобных устройств находится в зависимости от работы сотовых операторов и качества обеспечиваемой ими связи, а, кроме того, GSM сигнализации подвержены глушению посредством специального оборудования.
                            В связи с этим, на сегодняшний день наиболее популярными являются надёжные проводные охранные сигнализации.</p>
                         <h3>GSM СИГНАЛИЗАЦИЯ</h3> 
                         <p>GSM сигнализация - комплексы по охране объектов и территорий при помощи комплекта датчиков и с применением технологий GSM. Главной задачей этих систем является контроль за охраняемыми объектами, обнаружение вторжения на охраняемый объект или территорию и выдача тревожного сообщения при помощи телефонного звонка или SMS- сообщения.</p>  
                         <p>Принцип работы системы GSM сигнализации состоит в оборудовании охраняемой территории или объекта необходимым комплектом датчиков, контрольной панелью, концентратором охранных шлейфов и модулем GSM. Концентратор шлейфов и GSM- модуль зачастую объединены в одно устройство. При выдаче каким-либо из датчиков сигнала «Тревога» концентратором охранных шлейфов включается GSM- модуль, который по сети сотовой связи передает тревожное SMS-сообщение или совершает звонок на один из заранее указанных телефонов владельцу или в службу охраны. Постановка на охрану и отключение GSM сигнализации может осуществляться при помощи радио брелоков или непосредственно с сотового телефона - с помощью SMS или звонка (при наличии интегрированного голосового модуля).</p>
                         
                    </div>
                </div>
            </div>
        </>
    );
};

export default Okhrannayasignalizatsiya;
