import React from 'react';
import garpun from '../../images/services-images/garpun_2_0.jpg'

const Garpun2 = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Ручной комплекс борьбы с БПЛА коммерческого типа «Гарпун-2»</h1>
                        <img src={garpun} alt='garpun'/>
                        <p>Изделие «Гарпун-2» предназначено для создания пространственных зон безо­пасности от беспилотных летательных аппаратов (КомБПЛА) коммерческого типа, путем прицельно-пространствен­ной постановки помех в диапазонах ча­стот каналов управления и навигации КомБПЛА (GPS/ГЛОНACC/Beidou), ли­шая возможности определения своего местоположения и получения команд управления от оператора.</p>
                        <p>Возможен стационарный вариант испол­нения при котором комплекс будет уста­новлен на двухосевой ротатор с внеш­ним пультом управления.</p>
                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Активацию комплекса нажатием на спусковой крючок;</p>
                            <p data-number="2">Выбор режима помехового воздействия на КомБПЛА;</p>
                            <p data-number="3">Максимальное количество блокируемых диапазонов - 7;</p>
                            <p data-number="4">Контроль уровня заряда аккумуляторной батареи;</p>
                            <p data-number="5">Работу от встроенной АКБ не менее 40 минут, с возможностью ее замены (опционально);</p>
                            <p data-number="6">Дальность действия до 300 метров.</p>
                        </div>
                        <h2>Диапазоны рабочих частот</h2>
                        <div className='spisok'>
                            <p data-number="1">433 МГц (433,05-434,79 МГц);</p>
                            <p data-number="2">868 МГц (863-870 МГц);</p>
                            <p data-number="3">900 МГц (902-928 МГц);</p>
                            <p data-number="4">1,17645 ГГц;</p>
                            <p data-number="5">1,19179 ГГц;</p>
                            <p data-number="6">1,202025 ГГц;</p>
                            <p data-number="7">1,2276 ГГц;</p>
                            <p data-number="8">1,246 ГГц;</p>
                            <p data-number="9">1,57542 ГГц;</p>
                            <p data-number="10">1,602 ГГц;</p>
                            <p data-number="11">2,4 ГГц (2,4-2,483 ГГц);</p>
                            <p data-number="11">5,8 ГГц (5,725-5,875 ГГц).</p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное;</p>
                            <p data-number="2">Стационарное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от - 20°С до + 45°С</p>
                        </div>
                        <h2>Электропитание:</h2>
                        <p>От встроенной сменной АКБ в мобильном исполнении. Потребляемая мощность не более 150 Вт</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Garpun2;
