import React, { useEffect } from 'react';
import './ServicesPage.css';
import cifrovoj_fon_nachalo_starnicy from '../../images/services-images/cifrovoj_fon_nachalo_starnicy.jpg'
const STS = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Cпециальных технические средства (СТС)</h1>
                        <div className='bimage'>
                            <img src={cifrovoj_fon_nachalo_starnicy} alt=''/>
                        </div>
                        
                        <p>
                            Компания «АскомМЕТ» осуществляет реализацию и ремонт специальных технических 
                            средств (СТС) 
                            для субъектов оперативно-розыскной деятельности.", 
                            на основании (Лицензия АТ № 049), реализацию средств криптографической защиты 
                            информации 
                            (Лицензия СК № 351), а также проводит работы со сведениями, состовляющими 
                            государственные 
                            секреты РК (Резрешение №119/33/651).
                            Проводит поставку, инсталляцию оборудования и систем для выявления каналов утечки информации
                            и выполнения работ по специальным исследованиям и аттестации объектов информатизации.
                        </p>
                        <p>
                            Информацию заинтересованных лицам можно получить по телефонам +7 (7172) 55-27-93, +7 (7172) 55-27-97 или в офисе компании по адресу: Республики Казахстан, ZOOX6D6, г. Астана, ул. Сарысу, д.12/1.
                        </p>
                    </div>
                </div>
            </div>

        </>
    );
};

export default STS;
