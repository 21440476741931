import React, { useEffect } from 'react';
import network from "../../images/services-images/network.jpg"
import network_1 from "../../images/services-images/network_1.jpg"
const Kompyuternyyeseti = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Компьютерные сети</h1>
                        <img src={network} alt='el_set' />
                        <p>Структурированная кабельная система (СКС), предназначена для создания слаботочных систем. Основным принципом создания СКС, является интеграция вычислительных, телефонных сетей, систем безопасности, видеонаблюдения и других коммуникационных сетей в едином кабельном пространстве, т.е. объединение всего оборудования в здании. Кабельная сеть создается в расчете на длительную перспективу и исключает необходимость прокладки дополнительных кабелей при изменении требований к системе коммуникаций и при подключении нового или перемещении старого оборудования.</p>

                        <h3>В общем случае СКС включает три подсистемы:</h3>
                        <p>
                        Подсистема внешних магистралей. На практике, эта подсистема соединяет в единую сеть здания, расположенные на одной территории. Если СКС устанавливается только в одном здании, подсистема внешних магистралей отсутствует</p>
                        <p>
                        Подсистема внутренних магистралей (вертикальная СКС) - включает проложенные между кроссовыми здания (КЗ) и кроссовыми этажей (КЗ) магистральные кабели. Фактически, кабели данной подсистемы связывают между собой отдельные этажи здания или разнесенные помещения в пределах одного здания</p>
                        <p>
                        Горизонтальная подсистема (горизонтальная СКС) - включает кабели, проложенные между КЗ и розеточными модулями информационных розеток, информационные розетки и коммутационное оборудование, к которому подключаются горизонтальные кабели.</p>
                        <h3>Услуги</h3>
                        <p>Компания «АскомМЕТ» выполняет весь комплекс работ, связанных с построением СКС, ЛВС, телефонных и электрических сетей. Специалисты нашей компании осуществляют проектирование локальных компьютерных сетей, построение ЛВС, проектирование СКС, монтаж СКС, тестирование и сертификацию построенной СКС. Наша организация обладает необходимыми лицензиями и аттестатами для осуществления деятельности в области создания сетей: построения компьютерных сетей, построения локальных сетей, а также сертификатами ведущих производителей СКС. Наши специалисты обладают всеми необходимыми профессиональными знаниями в области создания компьютерных сетей, создания локальных сетей.</p>
                        <img src={network_1} alt='el_set' />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kompyuternyyeseti;
