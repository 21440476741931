import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './Header.css';
import Logo from '../../images/Logo.png';
import { FaBars } from "react-icons/fa";

const Header = ({ activeSection }) => {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef(null); // Ref для меню

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleScrollToSection = (event, sectionId) => {
        event.preventDefault();

        // Проверяем, находимся ли мы на главной странице
        if (window.location.pathname === "/") {
            const element = document.getElementById(sectionId);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            // Если не на главной странице, переносим на главную и добавляем небольшую задержку для скроллинга
            navigate("/");
            setTimeout(() => {
                const element = document.getElementById(sectionId);
                if (element) {
                    element.scrollIntoView({ behavior: "smooth" });
                }
            }, 100); // Задержка в 100 мс для корректной загрузки DOM
        }

        // Закрываем мобильное меню
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        // Проверяем, произошел ли клик вне меню
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        // Добавляем обработчик кликов на документ
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Убираем обработчик кликов при размонтировании компонента
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className="header">
            <a href="/"><img className="logo" src={Logo} alt="logo" /></a>
            <nav ref={menuRef} className={`navbar ${isOpen ? 'active' : ''}`}>
                <li
                    onClick={(e) => handleScrollToSection(e, 'about')}
                    className={activeSection === 'about' ? 'active' : ''}
                >
                    О НАС
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'services')}
                    className={activeSection === 'services' ? 'active' : ''}
                >
                    УСЛУГИ
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'partners')}
                    className={activeSection === 'partners' ? 'active' : ''}
                >
                    ПАРТНЕРЫ
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'news')}
                    className={activeSection === 'news' ? 'active' : ''}
                >
                    НОВОСТИ
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'vacancies')}
                    className={activeSection === 'vacancies' ? 'active' : ''}
                >
                    ВАКАНСИИ
                </li>
                <li
                    onClick={(e) => handleScrollToSection(e, 'contacts')}
                    className={activeSection === 'contacts' ? 'active' : ''}
                >
                    КОНТАКТЫ
                </li>
            </nav>
            <div className="menu" onClick={toggleMenu}>
                <FaBars />
            </div>
        </header>
    );
};

export default Header;
