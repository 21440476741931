import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './AboutPage.css';
import { TiArrowBack } from "react-icons/ti";
import license1 from '../../images/license/1.jpg';
import license2 from '../../images/license/2.jpg';
import license3 from '../../images/license/3.jpg';


const AboutUsPage = () => {
    const [selectedLicense, setSelectedLicense] = useState(null);

    const licenses = [
        { image: license1, title: "Лицензия 1" },
        { image: license2, title: "Лицензия 2" },
        { image: license3, title: "Лицензия 3" },

    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();

    const openLicense = (image) => {
        setSelectedLicense(image);
    };

    const closeModal = () => {
        setSelectedLicense(null);
    };

    return (
        <>
            <button className="back-button" onClick={() => navigate(-1)}>
                <i><TiArrowBack /></i> Назад
            </button>

            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>О компании</h1>
                        <p>
                            ТОО АскомМЕТ работает на рынке Казахстана с 2011 года.
                            Офис компании находится в городе Астане,
                            а также имеется представительство в городе Тараз.
                        </p>
                        <h2>Широкий спектр оборудования по безопасности:</h2>
                        <div className='features'>
                            <ul>
                                <li>Технические средства защиты информации и обнаружения каналов утечки информации.</li>
                                <li>Антитеррористические и досмотровое оборудование.</li>
                                <li>Программно-аппаратные средства защиты информации от НСД.</li>
                                <li>Измерительное оборудование для проведения специальных исследований.</li>
                                <li>Оптические приборы (приборы ночного видения)</li>
                            </ul>
                        </div>
                        <h2>Заказчики</h2>
                        <p>Нашими постоянными клиентами являются государственные организации и частные компании:</p>
                        <div className='about-customer'>
                            <div className='about-customer-left-side'>
                            </div>
                            <div className='about-customer-right-side'>
                                <p className='ots'></p>
                                <div className='customers'>
                                    <div>
                                        <h2>МВД</h2>
                                        <p>Министерство внутренних дел Республики Казахстан</p>
                                    </div>
                                    <div>
                                        <h2>«Қазақстан Темір Жолы»</h2>
                                        <p>Крупнейший оператор магистральной железнодорожной сети Казахстана</p>
                                    </div>
                                    <div>
                                        <h2>Национальное бюро</h2>
                                        <p>Национальное бюро по противодействию коррупции Агентства Республики Казахстан</p>
                                    </div>
                                    <div>
                                        <h2>«КазМунайГаз»</h2>
                                        <p>АО Национальная компания «КазМунайГаз»</p>
                                    </div>
                                    <div>
                                        <h2>Комитет Национальной Безопасности Казахстана</h2>
                                    </div>

                                    <div>
                                        <h2>Министерство обороны Республики Казахстан</h2>
                                    </div>
                                    <div>
                                        <h2>АО «Цеснабанк»</h2>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <h2>Лицензии</h2>
                        <div className="licenses-gallery">
                            {licenses.map((license, index) => (
                                <div key={index} className="license-item" onClick={() => openLicense(license.image)}>
                                    <img
                                        src={license.image}
                                        alt={license.title}
                                        className="license-thumbnail"
                                    />
                                   
                                </div>
                            ))}
                        </div>

                        {selectedLicense && (
                            <div className="modal-overlay-1" onClick={closeModal}>
                                <div className="modal-content-1" onClick={(e) => e.stopPropagation()}>
                                    <img src={selectedLicense} alt="Просмотр лицензии" className="full-image" />
                                    <button className="close-button" onClick={closeModal}>
                                        Закрыть
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AboutUsPage;
