import React, {useEffect} from 'react';
import el_set from "../../images/services-images/el_set.jpg"
const Elektricheskiyeseti = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Электрические сети</h1>
                        <img src={el_set} alt='el_set'/>
                        <p>Основой деятельности любой фирмы, организации, предприятия является электрическая сеть. Грамотное проектирование электрических сетей сэкономит ваши средства в будущем, и создаст конкурентное преимущество на старте</p>
                        
                        <h3>Монтаж</h3>
                        <p>Мы осуществляем монтаж электрических сетей, связанные с электрикой квартир, коттеджей, офисов, объектов торговли, складских и производственных построек, новостроек.</p>
                        <h3>Разрешения</h3>
                        <p>На все виды электромонтажа наша компания имеет все необходимые документы и разрешения. Проектирование электрических сетей и работы осуществляются с соблюдением правил снип и нормативными документами.</p>
                        <h3>Гарантии</h3>
                        <p>Обращаясь в нашу компанию, Вы можете быть уверены в высоком качестве предоставляемых нами услуг. Работы ведутся дипломированными специалистами с большим опытом работы. На все работы предоставляется гарантия.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Elektricheskiyeseti;
