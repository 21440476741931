import React, { useEffect } from 'react';
import screenshot_4 from '../../images/services-images/screenshot_4.png'
const Usj01 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Ультразвуковой глушитель USJ-01</h1>
                        <p>Ультразвуковой глушитель USJ-01 – эффективное устройство, предназначенное для предотвращения несанкционированного прослушивания и записи конфиденциальных разговоров через различные устройства, такие как:</p>
                        <div className='features'>
                            <ul>
                                <li>GSM-передатчики;</li>
                                <li>устройства передающие информацию по радиоканалам;</li>
                                <li>мобильные телефоны;</li>
                                <li>аналоговые и цифровые диктофоны;</li>
                                <li>проводные микрофоны.</li>
                            </ul>
                        </div>
                        <img src={screenshot_4} alt='screen' />
                        <h2>Принцип работы:</h2>
                        <div className='features'>
                            <ul>
                                <li>Создание направленного излучения сложной структурированной помехи в ультразвуковом диапазоне, неслышимой для человеческого уха, но воздействующей своим звуковым давлением непосредственно на мембрану микрофона;</li>
                                <li>Направленность действия – круговое (360°).</li>
                                <li>В больших помещениях необходимо устанавливать несколько и правильно направленных устройств для эффективного подавления микрофонов.</li>
                            </ul>
                            <h2>Технические характеристики:</h2>
                            <ul>
                                <li>классификация устройства: класс 3, SELV;</li>
                                <li>источник питания: 12V DC/1.5A;</li>
                                <li>максимальная потребляемая мощность: 18 Вт;</li>
                                <li>частота ультразвука: 20 кГц;</li>
                                <li>уровень звукового давления: 106 дБ на расстоянии 2 м;</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Usj01;
