import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isNavbarOpen, setIsNavbarOpen] = useState(true); // Начальное состояние для больших экранов — всегда открыто
    const [isDynamic, setIsDynamic] = useState(window.innerWidth < 1110);

    useEffect(() => {
        // Обработчик изменения размера окна
        const handleResize = () => {
            const isSmallScreen = window.innerWidth < 1424;
            setIsDynamic(isSmallScreen);

            // Если экран больше 1110px, всегда показываем навбар
            if (!isSmallScreen) {
                setIsNavbarOpen(true);
            }
        };

        window.addEventListener('resize', handleResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleBackClick = () => {
        navigate(-1);
    };

    const toggleNavbar = () => {
        if (isDynamic) {
            setIsNavbarOpen(!isNavbarOpen);
        }
    };

    const antiterrorLinks = [
        { to: "/antiterror/persei-17", label: "Персей - 17" },
        { to: "/antiterror/vorota", label: "Противотаранные ворота" },
        { to: "/antiterror/barriers", label: "Противотаранные шлагбаумы" },
        { to: "/antiterror/ptu", label: "ПТУ шлагбаумного типа" },
        { to: "/antiterror/blocker", label: "Противотаранные блокираторы" }
    ];

    const antidroneLinks = [
        { to: "/antidrone/sled-1", label: "Cистема обнаружения БПЛА типа «След-1»" },
        { to: "/antidrone/sled-5", label: "Cистема обнаружения БПЛА типа «След-5»" },
        { to: "/antidrone/kupol", label: "Комплекс борьбы с БПЛА типа «Купол»" },
        { to: "/antidrone/kupol-7", label: "Комплекс борьбы с БПЛА типа «Купол-7»" },
        { to: "/antidrone/garpun-1", label: "Ручной комплекс борьбы с БПЛА «Гарпун-1»" },
        { to: "/antidrone/garpun-2", label: "Ручной комплекс борьбы с БПЛА «Гарпун-2»" },
    ];

    const informsecLinks = [
        { to: "/informsec/dallaslock", label: "DALLASLOCK" },
        { to: "/informsec/octopus", label: "OCTOPUS" },
        { to: "/informsec/usj-01", label: "Ультразвуковой глушитель USJ-01" },
        { to: "/informsec/usj-02-disc", label: "Ультразвуковой глушитель USJ-02-Disc" },
    ];

    const integratedSecuritySystemLinks = [
        { to: "/integratedsecuritysystem/videonablyudeniye", label: "Видеонаблюдение" },
        { to: "/integratedsecuritysystem/skud", label: "СКУД" },
        { to: "/integratedsecuritysystem/kompyuternyye-seti", label: "Компьютерные сети" },
        { to: "/integratedsecuritysystem/elektricheskiye-seti", label: "Электрические сети" },
        { to: "/integratedsecuritysystem/okhrannaya-signalizatsiya", label: "Охранная сигнализация" },
        { to: "/integratedsecuritysystem/pozharnaya-signalizatsiya", label: "Пожарная сигнализация" },
        { to: "/integratedsecuritysystem/telefonnyye-kommunikatsii", label: "Телефонные коммуникации" },
        { to: "/integratedsecuritysystem/sistema-umnyy-dom", label: "Система Умный дом" },
        { to: "/integratedsecuritysystem/intellektualnoye-zdaniye", label: "Интеллектуальное здание" },
    ];

    const forensicLinks = [];

    const renderLinks = () => {
        if (location.pathname.startsWith('/antiterror')) {
            return antiterrorLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/antidrone')) {
            return antidroneLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/informsec')) {
            return informsecLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/forensic')) {
            return forensicLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        } else if (location.pathname.startsWith('/integratedsecuritysystem')) {
            return integratedSecuritySystemLinks.map((link, index) => (
                <li key={index}>
                    <NavLink
                        to={link.to}
                        className={({ isActive }) => isActive ? "active-link" : ""}
                        style={{ display: 'block', width: '100%' }}
                    >
                        {link.label}
                    </NavLink>
                </li>
            ));
        }
        return null;
    };

    return (
        <nav className={`services-navbar ${isNavbarOpen ? 'open' : ''}`}>
            {/* Кнопка управления показывается только на маленьких экранах */}
            {isDynamic && (
                <button className="toggle-button" onClick={toggleNavbar}>
                    {isNavbarOpen ? '×' : '☰'}
                </button>
            )}
            <ul>
                {renderLinks()}
                <li>
                    <button onClick={handleBackClick} className="but-back">Назад</button>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
