import React, { useEffect } from 'react';

const Antiterror = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>                
                    <div className='aboutus-header'>
                        <h1>Антитеррористическая защита</h1>
                        <p>
                            В последнее время в мире все острее стоит проблема терроризма. Этому вопросу уделяется большое значение
                            на правительственном уровне во многих странах. Поэтому неудивительно, что на развитие области,
                            отвечающей за антитеррористическую деятельность, выделяются большое количество финансовых и научных ресурсов.
                        </p>
                        <p>
                            На рынке техники существует большое количество оборудования, предназначенного для противодействия терроризму.
                            Все они в большей или меньшей степени эффективны, и их применение обеспечивает определенный уровень защищённости.
                        </p>
                        <p>
                            По принципам действия устройства антитеррора можно условно разделить на 2 группы:
                        </p>
                        <div className='spisok'>
                            <p data-number="1">Устройства, выявляющие различными способами взрывные устройства и взрывчатые вещества;</p>
                            <p data-number="2">Устройства, нейтрализующие взрыв или последствия взрыва.</p>
                        </div>
                        <p>
                            К первой группе относятся различные приборы, производящие визуальный осмотрпомещения; термографы, рентгеновские комплексы, металлодетекторы и другие обнаружители взрывных устройств.
                        </p>
                        <p>
                            Отдельной группой стоят приборы, способные в той или иной мере предотвратить действия или последствия действий взрывных устройств различными способами: например, локализовать взрыв или блокировать работу радио управляемых ВУ путем создания шумовой помехи.
                        </p>
                        <p>
                            К сожалению, в мире еще пока не изобрели устройство, которое может полностью нейтрализовать взрыв или предотвратить его. Однако на рынке спецтехники появились устройства, обеспечивающие определенную степень защищённости от взрывных устройств (ВУ).
                        </p>
                        <p>
                            Существуют приборы, позволяющие локализовать взрыв. Они обеспечивают снижение выделяемой при взрыве взрывных устройств энергии и, соответственно, уменьшают возможность разрушений и повреждений от осколков, а также существенно снижают возможность возникновения пожаров. Однако, применение таких приборов рационально только в случае, если взрывное или просто подозрительное устройство уже обнаружено, что не всегда является возможным.
                        </p>
                        <p>
                            Есть и другие устройства защиты от взрыва, принцип работы которых состоит в блокировке работы радиоуправляемых взрывных устройств путем создания шумовой помехи. Эти устройства целесообразно устанавливать в потенциально опасных местах, где они будут постоянно работать. Наиболее часто такие блокираторы устанавливают на подвижных объектах: автомашинах, бронетранспортерах и т.п.
                        </p>
                        <p>
                            Однако применение устройств, описанных выше, неспособно предотвратить взрыв в любой ситуации, поэтому рекомендуется использовать также и другие средства противодействия ВУ.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Antiterror;
