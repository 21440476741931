import React, { useEffect } from 'react';

const Octopus = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>OCTOPUS</h1>
                        <p>Создайте единое пространство связных данных, гипотез и знаний. Получите возможность иметь полную картину исследуемой предметной области вне зависимости от размытости временного горизонта и природы входной информации. С комплексом программных средств Октопус Ваши большие данные будут работать на Вас.</p>
                        <h2>Функциональные возможности:</h2>
                        <div className='spisok'>
                            <p data-number="1">Формирование схемы данных удовлетворяющей необходимостям подразделения</p>
                            <p data-number="2">Ведение базы данных учета объектов в графовом и табличном режимах</p>
                            <p data-number="3">Агрегация данных, нахождение скрытых связей и связей через цепочку сущностей</p>
                            <p data-number="4">Визуализация и стилизация диаграмм связей объектов различной структуры</p>
                            <p data-number="5">Формирование интерактивных отчетов и досье</p>
                            <p data-number="6">Визуальная классификация и фильтрация сущностей, включая фильтрацию с помощью задания временного окна</p>
                            <p data-number="7">Построение статистических графиков и временных рядов</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Octopus;
