import React, { useEffect } from 'react';
import umn_dom_1 from "../../images/services-images/umn_dom_1.jpg"
const Sistemaumnyydom = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Система Умный дом</h1>
                        <img src={umn_dom_1} alt='el_set' />
                        <p>Создание систем "умный дом" или интеллектуального здания является одним из важных факторов для успешного ведения бизнеса. Данная концепция - это новый подход в организации жизнеобеспечения здания. Такая система не только автоматизирует Вашу жизнь, но и предоставляет дополнительную защиту и безопасность.</p>
                        <p>Согласно оценкам зарубежных специалистов, интеллектуальные компоненты инженерных систем и сетей здания составляют примерно 5-7% от общей стоимости всех систем и увеличивают стоимость строительства 1 кв. м. здания на 5-35 долларов США. В тоже время именно интеллектуальные системы позволяют ежегодно экономить до 5-7% расходов на электро-, тепло- и водоснабжение интеллектуального здания и окупаются уже на 5- й год его эксплуатации. Начиная с 6-го года, интеллектуальное здание начинает приносить владельцам дополнительно 5-7% чистой ежегодной прибыли от всех эксплуатационных расходов на здание.</p>
                        <p>На здания, оснащенные системами автоматизации ставки страхования могут быть снижены до 60% от стандартных ставок.
                        Система Умный дом умеет распознавать конкретные ситуации, происходящие в здании или на прилегающей территории, и соответствующим образом на них реагировать.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sistemaumnyydom;
