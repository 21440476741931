import React, { useEffect, useRef } from "react";
import './Home.css';
// import Camera from '../../images/cam.gif';

// Home.js
const Home = ({ onOpenModal }) => {
    const aboutTitleRef = useRef(null);
    const aboutDescriptionRef = useRef(null);
    const contactUsRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (aboutTitleRef.current) {
                aboutTitleRef.current.classList.add('visible');
            }
        }, 300);

        setTimeout(() => {
            if (aboutDescriptionRef.current) {
                aboutDescriptionRef.current.classList.add('visible');
            }
        }, 600);

        setTimeout(() => {
            if (contactUsRef.current) {
                contactUsRef.current.classList.add('visible');
            }
        }, 900);
    }, []);

    return (
        <div className="home-content">
            <div className="overlay-image-1"></div>
            <div className="overlay-image-2"></div>
            <div className="overlay-image-3"></div>

            <div className="home">
                <div className="home-text">
                    <div className="home-title" ref={aboutTitleRef}>
                    <p>Ваша безопасность -</p>
                    <p>Генерируем, Воплощаем!</p>
                    </div>
                    <div className="home-description" ref={aboutDescriptionRef}>
                        Мы предоставляем высококачественные решения 
                        в области видеонаблюдения, охранных систем 
                        и контроля доступа, обеспечивая безопасность
                        и спокойствие наших клиентов с 2011 года.
                    </div>
                    <div className="contact-us" ref={contactUsRef}>
                        <input type="button" value="Связаться с нами" onClick={onOpenModal} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;

