import React, { useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { vacanciesData } from "../../components/Vacancies/Vacancies";
import { IoMdTime } from "react-icons/io";
import { LuWallet } from "react-icons/lu";
import { BsGeoAlt } from "react-icons/bs";
import { MdWorkOutline } from "react-icons/md";
import { TiArrowBack } from "react-icons/ti";
import QR from '../../images/2ГИС.png'

import './VacancyDetail.css';

const VacancyDetail = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const navigate = useNavigate();
    const { id } = useParams(); // Получаем ID из URL
    const vacancy = vacanciesData.find(vacancy => vacancy.id === parseInt(id));

    if (!vacancy) {
        return <div>Вакансия не найдена</div>;
    }

    return (
        <>
            <button className="back-button" onClick={() => navigate(-1)}>
                <i><TiArrowBack /></i> Назад
            </button>
            <div className='vac-two-blocks'>

                <div className="vacancy-detail">
                    <div className='vacancies-description'>
                        <div className='vac-time'>{vacancy.timePosted}</div>
                        <div className='vac-title'>
                            <h2>{vacancy.title}</h2>
                        </div>
                    </div>
                    <div className='vac-bottom'>
                        <div className='vac-icons'>
                            <p><i><IoMdTime /></i>{vacancy.workType}</p>
                            <p><i><LuWallet /></i>{vacancy.salary}</p>
                            <p><i><MdWorkOutline /></i>{vacancy.experience}</p>
                            <p><i><BsGeoAlt /></i>{vacancy.location}</p>
                        </div>
                        
                    </div>
                    <div className='vac-button'>

<input type='button' value='Откликнуться' />

</div>
                    <div className="full-desc">
                        <div className="job-description">
                            <h2>Описание вакансии:</h2>
                            <p>
                                Мы ищем опытного бухгалтера, который будет полностью отвечать за ведение бухгалтерского и
                                налогового учета. В обязанности входит сдача налоговых и статистических отчетов, инвентаризация,
                                учет банковских операций и основных средств, а также налоговый учет. Необходим опыт работы в
                                программе "1С" 8.3 версии.
                            </p>
                        </div>
                        <div className="job-key">
                            <h2>Обязанности:</h2>
                            <ul>
                                <li>Полное ведение бухгалтерского и налогового учета</li>
                                <li>Сдача налоговых и статистических отчетов</li>
                                <li>Ведение бухгалтерского учета, инвентаризация, банк, основные средства, налогового учета, сдача налоговой отчетности.</li>
                                <li>Работа с программой "1С" 8.3 версии.</li>
                            </ul>
                        </div>
                        <div className="job-skills">
                            <h2>Требования:</h2>
                            <ul>
                                <li>Знание общеустановленных режимов налогообложения</li>
                                <li>Знание производственного цикла</li>
                                <li>Знание законодательство РК и МСФО</li>
                                <li>Высшее экономическое образование</li>
                                <li>Опыт работы не менее 3 лет</li>
                                <li>Знание законодательства о бухгалтерском учете</li>
                                <li>Финансовое, налоговое и хозяйственное законодательство</li>
                                <li>Нормативные и методические документы по вопросам организации бухгалтерского учета и составления отчетности, хозяйственно-финансовой деятельности организации</li>
                                <li>Желательно наличие сертификата проф. бухгалтера</li>
                            </ul>
                        </div>
                        <div className="terms">
                            <h2>Условия:</h2>
                            <p>Официальное трудоустройство, график работы 5/2</p>
                        </div>
                        <div className="address-map"></div>
                    </div>
                </div>
                <div className="map-container">
                    <iframe
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2501.347306341175!2d71.47793012199175!3d51.17582138598434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4245815032876cad%3A0x46133733b64a9ead!2z0JDRgdC60L7QvNCc0JXQog!5e0!3m2!1sru!2skz!4v1731653220549!5m2!1sru!2skz"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                    <img src={QR} alt='QR'></img>
                    <p><a href='https://go.2gis.com/jts2g' target="_blank" rel="noopener noreferrer">Смотрите в 2ГИС</a></p>
                </div>
            </div>

        </>
    );
};

export default VacancyDetail;
