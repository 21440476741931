import React, { useEffect } from 'react';
import firealarm from "../../images/services-images/firealarm.jpg"
const Pozharnayasignalizatsiya = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Пожарная сигнализация</h1>
                        <img src={firealarm} alt='el_set' />
                        <p>Современное здание в сегодняшних реалиях не может существовать без пожарной сигнализации. Пожарная сигнализация представляет собой сложный комплекс технических средств, служащих для своевременного обнаружения возгорания. Как правило, пожарная сигнализация интегрируется в комплекс, объединяющий системы безопасности и инженерные системы здания, обеспечивая достоверной адресной информацией системы оповещения, пожаротушения, дымоудаления, контроля доступа и др.</p>
                        <div className='spisok'>
                            <p data-number="1">Оборудование централизованного управления пожарной сигнализацией (например, центральный компьютер с установленным на нем ПО для управления пожарной сигнализацией; в небольших системах пожарной сигнализации задачи централизованного управления выполняет пожарная панель);</p>
                            <p data-number="2">Оборудование сбора и обработки информации с датчиков пожарной сигнализации: приборы приемно-контрольные пожарные (панели);</p>
                            <p data-number="3">Сенсорные устройства - датчики и извещатели пожарной сигнализации.</p>
                        </div>
                        <p>Пожарная сигнализация проектируется и устанавливается нашими специалистами. Мы используем весь спектр оборудования, сертифицированного к применению на территории РК.</p>
                        <p>Растущие требования к системам противопожарной защиты объектов стимулируют сегодня быстрое развитие этой области техники. В настоящее время существует множество современных систем пожарной сигнализации различного уровня сложности - от простых устройств до сложных микропроцессорных комплексов. Они обеспечивают контроль охраняемой территории с помощью специальных датчиков - извещателей, чётко фиксирующих любые нарушения охраняемой зоны и выдающих звуковое оповещение. Многие системы безопасности имеют возможность дистанционной передачи сигнала тревоги на центральный пульт охраны и выполняют ряд других сервисных функций.</p>
                        <p>Мы обеспечиваем установку системы пожарной сигнализации на любых объектах: офисы, склады, магазины, торговые павильоны и подсобные помещения, гаражные комплексы, биржи, банки, промышленные предприятия и гостиничные комплексы.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Pozharnayasignalizatsiya;
