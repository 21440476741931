import React from 'react';
import p_17 from '../../images/services-images/p_17.jpg'
import p_17_1 from '../../images/services-images/p_17_1.jpg'
const Persei = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Персей - 17</h1>
                        <img src={p_17} alt='p_17'/>
                        <p>Прибор предназначен для обеспечения безопасности в досмотровых зонах аэропортов, вокзалов, стадионов, на метрополитене и при проведении работ по обезвреживанию взрывных устройств.</p>
                        <p>Прибор обеспечивает предотвращение срабатывания радиовзрывателей при передаче командным прибором кодированного радиосигнала. Может применяться со всеми существующими средствами поиска взрывных устройств, не мешая их работе. Прибор обеспечивает подавление радиовзрывателей в диапазоне частот 20...2700 МГц и имеет непрерывный спектр.</p>
                        <img src={p_17_1} alt='p_17_1'/>
                        <p>Прибор размещен в стандартном кейсе «Пеликан», имеет встроенный источник питания, систему диагностики состояния со световой и звуковой индикацией и встроенное автоматическое зарядное устройство. Прибор имеет две убирающиеся широкополосные антенны.</p>
                        <p>Отличительные особенности:</p>
                        <div className='spisok'>
                            <p data-number="1">Переносной широкополосный блокиратор радиовзрывателей •Встроенные автономный источник питания на основе литий-ионного аккумулятора и зарядное устройство</p>
                            <p data-number="2">Внешнее питание от сети переменного тока 220 В 50 Гц •Встроенная цифровая система диагностики с сигнализацией о неисправности •Большое время автономной работы •Непрерывный спектр</p>
                            <p data-number="3">Всего две сверхширокополосные антенны обеспечивают излучение во всем диапазоне частот</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Persei;
