import React, { useEffect } from 'react';
import konfident12 from '../../images/services-images/konfident12.jpg'
import konfident13 from '../../images/services-images/konfident13.jpg'
const Dallaslock = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Линейка средств защиты информации "DALLASLOCK"</h1>
                        <p>Линейка собственных сертифицированных разработок Центра защиты информации компании
                            «Конфидент» - это лучшие по совокупности технико-экономических показателей программные и
                            программно-аппаратные решения для защиты информации, разграничения, защиты и контроля доступа,
                            межсетевого экранирования и доверенной загрузки, отвечающие современным требованиям
                            законодательства, обладающие необходимым и уникальным функционалом.</p>
                        <img src={konfident12} alt='' />
                        <img src={konfident13} alt='' />
                        <h2>Особенности и преимущества продуктов компании «КОНФИДЕНТ»</h2>
                        <p>Соответствие российскому законодательству в сфере ИБ (сертификаты ФСТЭК России, подготовка новых решений и
                            возможностей для сертификации в Министерстве обороны РФ).
                            Регулярное продление сертификатов и сертификация новой функциональности.
                            Решение широкого спектра задач заказчиков по защите информации – не только приведение в соответствие
                            требованиям законодательства.
                            «Бесшовная» интеграция продуктов Dallas Lock между собой.
                            Совместимость с ИТ/ИБ-решениями других производителей.
                            Расширенный функционал централизованного управления с возможностью построения отказоустойчивых доменов
                            безопасности.
                            Защита сложных гетерогенных сетевых инфраструктур с минимальными затратами.
                            Выгодные условия первичного приобретения и последующего сопровождения. </p>
                        <h2>Обеспечение комплексной информационной безопасности:</h2>
                        <p>Авторизация пользователей,
                            Контроль привилегированных пользователей,
                            Контроль целостности,
                            Аудит событий,
                            Разграничение доступа,
                            Защита и контроль доступа к устройствам и съемным машинным носителям информации,
                            Контроль утечек информации,
                            Межсетевое экранирование,
                            Централизованное управление информационной безопасностью,
                            Доверенная загрузка уровня платы расширения.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dallaslock;
