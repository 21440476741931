import React from 'react';
import './Preloader.css';

const Preloader = () => {
    return (
        <>
            <div className="preloader">
                <div className="dot dot1"></div>
                <div className="dot dot2"></div>
                <div className="dot dot3"></div>
            </div>
        </>
    );
};

export default Preloader;
