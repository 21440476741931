import React, { useState } from "react";
import './Services.css';
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Services = () => {
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    const services = [
        { 
            id: 1, 
            title: "Комплексная Система Безопасности", 
            description: "ТОО «АскомМЕТ» осуществляет проектирование, поставку, монтаж и инсталляцию комплексных систем безопасности от отечественных и мировых производителей, а также интегрирование новейших современных систем в уже имеющиеся комплексы безопасности.", 
            background: require('../../images/secure.jpg'), 
            path: "/integratedsecuritysystem" 
        },
        { 
            id: 2, 
            title: "Средства Защиты Информации", 
            description: "Средства защиты информации подразделяются на технические, программно-аппаратные и программные. На данный момент существует достаточно широкий выбор средств защиты информации как от несанкционированного доступа так и от утечки по техническим каналам.", 
            background: require('../../images/secure_info.jpg'), 
            path: "/informsec" 
        },
        { 
            id: 3, 
            title: "Цифровая криминалистика", 
            description: "Компания «АскомМЕТ» является официальным партнером ведущих мировых компаний по цифровой криминалистике.", 
            background: require('../../images/sts.jpg'), 
            path: "/forensic" 
        },
        { 
            id: 4, 
            title: "Анти - БПЛА", 
            description: "Решения по борьбе с беспилотными летательными аппаратами коммерческого типа.", 
            background: require('../../images/antibpla.jpg'), 
            path: "/antidrone" 
        },
        { 
            id: 5, 
            title: "Антитеррористическая защита", 
            description: "На рынке техники существует большое количество оборудования, предназначенного для противодействия терроризму. Все они в большей или меньшей степени эффективны, и их применение обеспечивает определенный уровень защищённости.", 
            background: require('../../images/terror.jpg'), 
            path: "/antiterror" 
        },
        { 
            id: 6, 
            title: "СТС", 
            description: "Компания «АскомМЕТ» осуществляет реализацию и ремонт специальных технических средств (СТС) для субъектов оперативно-розыскной деятельности.", 
            background: require('../../images/kriminal.jpg'), 
            path: "/sts" 
        },
    ];

    const previousIndex = (currentIndex + services.length - 1) % services.length;
    const nextIndex = (currentIndex + 1) % services.length;

    const handleNext = () => {
        setCurrentIndex((currentIndex + 1) % services.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((currentIndex + services.length - 1) % services.length);
    };

    const handleMoreDetails = (service) => {
        navigate(service.path);
    };

    const handleBlockClick = (index) => {
        setCurrentIndex(index); 
    };

    return (
        <>
            <div className="section">
                <div className="section-title"><h1>НАШИ УСЛУГИ</h1></div>
                <div className="service-carousel">
                    <button className="carousel-arrow left-arrow" onClick={handlePrevious}><FaArrowLeft /></button>
                    <div 
                        className={`service-block ${currentIndex === previousIndex ? 'left' : ''}`}
                        onClick={() => handleBlockClick(previousIndex)}
                    >
                        <div
                            className="service-image"
                            style={{ backgroundImage: `url(${services[previousIndex].background})` }}
                        />
                        <h2 className="service-title">{services[previousIndex].title}</h2>
                    </div>

                    <div className={`service-block center`}>
                        <div
                            className="service-image"
                            style={{ backgroundImage: `url(${services[currentIndex].background})` }}
                        />
                        <span className="service-title">{services[currentIndex].title}</span>
                        <span className="service-description">{services[currentIndex].description}</span>
                        <button className="more-details-button" onClick={() => handleMoreDetails(services[currentIndex])}>Подробнее</button>
                    </div>

                    <div 
                        className={`service-block ${currentIndex === nextIndex ? 'right' : ''}`}
                        onClick={() => handleBlockClick(nextIndex)}
                    >
                        <div
                            className="service-image"
                            style={{ backgroundImage: `url(${services[nextIndex].background})` }}
                        />
                        <span className="service-title">{services[nextIndex].title}</span>
                    </div>

                    <button className="carousel-arrow right-arrow" onClick={handleNext}><FaArrowRight /></button>
                </div>
            </div>
        </>
    );
};

export default Services;
