import React, { useEffect } from 'react';
import umn_dom_1 from "../../images/services-images/umn_dom_1.jpg"
import home_0 from "../../images/services-images/home_0.jpg"
const Intellektualnoyezdaniye = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Интеллектуальное здание</h1>
                        <img src={umn_dom_1} alt='umn_dom_1' />
                        <h3>Единая система вместо отдельных устройств управления</h3>
                        <p>В сравнении с классическими инженерными и слаботочными системами система умный дом, управления интеллектуального здания предоставляет заметные преимущества. Все подсистемы, независимо функционирующие друг от друга, интегрируются через шину коммутации в единую коммуникационную систему. Доступными становятся следующие функции:</p>
                        <div className='features'>
                            <ul>
                                <li>Управление светом;</li>
                                <li>Управление отоплением и вентиляцией;</li>
                                <li>Климат контроль;</li>
                                <li>Управление звуком (мультирум);</li>
                                <li>Управление жалюзи;</li>
                                <li>Интеграция с системой охраны;</li>
                                <li>Управление энергообеспечением;</li>
                                <li>Центральная автоматизация.</li>
                            </ul>
                        </div>
                        <img src={home_0} alt='umn_dom_1' />
                        <p>ТОО «АскомМЕТ» предлагает комплексные решения по автоматизации жилых зданий и объектов коммерческого и административного характера, включая предпроектное исследование объекта, и проектирование. У нас большой выбор оборудования умный дом. Мы проводим серьезную аналитическую работу, чтобы удовлетворить потребности нашего клиента и предоставить в его пользование систему, решающую задачи заказчика наиболее простым и удобным способом.</p>
                        <p>Каждый проект по автоматизации индивидуален и реализуется с помощью различных технических решений.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Intellektualnoyezdaniye;
