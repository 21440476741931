import React, { useEffect, useRef } from "react";
import './About.css';
import { useNavigate } from "react-router-dom";
import { MdOutlineDoubleArrow } from "react-icons/md";

const About = () => {
    const navigate = useNavigate();
    const blockRef = useRef(null);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "0px",
            threshold: 0.1
        };

        const handleIntersection = (entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("fade-in");
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, options);

        const currentBlock = blockRef.current;
        if (currentBlock) {
            observer.observe(currentBlock);
        }

        return () => {
            if (currentBlock) {
                observer.unobserve(currentBlock);
            }
        };
    }, []);

    const handleMoreClick = () => {
        navigate("/aboutus");
    };

    

    return (
        <div className="about-container">
          
            <div className="about-us">
                <h1>АскомМЕТ</h1>
                    <p>Приветствуем Вас на нашем сайте систем безопасности. Компания более 13 лет работает в данной сфере и занимает лидирующие позиции на рынке.</p>
                    <p>Мы можем предложить огромный выбор различных инструментов обеспечения безопасности</p>
                <ul>
                    <li>
                                системы видеонаблюдения - один из самых распространенных и эффективных способов 
                                обеспечения сохранности имущества и людей, подберем оптимальную систему, 
                                в наличии есть абсолютно незаметные устройства, позволяющие осуществлять мониторинг и запись;
                    </li>
                            <li>
                                охранная сигнализация - инструменты данной категории помогут обнаружить нарушителя
                                и известить о появлении постороннего на объекте.
                            </li>
                        </ul>
                <div className="more-button"><button onClick={handleMoreClick}>Узнать подробнее<i className="more-icon"><MdOutlineDoubleArrow /></i></button></div>
            </div>
        </div>
        

    );
};

export default About;
