import React from 'react';

const Blocker = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Противотаранные блокираторы</h1>
                        <p>Terra Surface Mount Blocker, Terra Shallow Blocker, Terra Blocker</p>
                        <p>Отвечают стандартам PAS 68 7,5т@80км/ч (=30т@Д0км/ч):</p>
                        <div className='spisok'>
                            <p data-number="1">Surface Mount Blockers<br/> Блокираторы для поверхностного монтажа. Общая высота конструкции в закрытом состоянии 150 мм.</p>
                            <p data-number="2">Shallow Blocker<br/> Мелкозаглубленные блокираторы, требующие закладки фундамента глубиной 400 мм.</p>
                            <p data-number="3">Terra Blocker</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Blocker;
