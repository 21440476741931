import React, {useEffect} from 'react';

const Skud = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>СКУД</h1>
                        <p>Система контроля и управления доступом (СКУД) предназначена для того, чтобы автоматически пропускать тех, кому это положено, и не пропускать тех, кому это запрещено. СКУД позволяет в любое время обеспечить контроль за ситуацией , порядок, безопасность персонала и посетителей, сохранность материальных ценностей и информации. Все отличия существующих систем состоят в том, насколько надежно, качественно, и удобно для пользователя осуществляются функции системы контроля доступа.</p>
                        <p>Системы контроля доступа делятся на простые и сложные. Простая, или автономная система - это электрический замок, установленный на двери и считыватель, установленный перед дверью. Ключом служат магнитные карты и дистанционные радиокарты. Возможен монтаж систем контроля доступа данного типа во всем здании. Причем считыватели могут быть запрограммированы на доступ во все, или определенные помещения. На входе в здание или дворовую территорию можно установить электрически управляемый турникет. Считывателей в этом случае будет два - на входе и выходе. Применяются такие системы и в гостиницах. На дверь каждого номера устанавливают замок. Съемщик попадает в свой номер с помощью запрограммированной магнитной карты. После выселения гостя, его карту перепрограммируют.</p>
                        <p>Система контроля доступа на несколько точек - использует много считывателей, которые объединяются в один «организм» с помощью пульта управления. Проход (и время прохождения) каждого посетителя регистрируется в базе данных компьютеров, подключенных к пульту. Проектирование и монтаж систем контроля доступа данного типа осуществляется в зависимости от потребностей предприятия.</p>
                        <p>СКУД полностью исключает влияние человеческого фактора на пропускной режим, так как контроль за доступом теперь возлагается на технику.</p>

                        </div>
                </div>
            </div>
        </>
    );
};

export default Skud;
