import React from 'react';
import garpun_1_0 from '../../images/services-images/garpun_1_0.jpg'
const Garpun1 = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Ручной комплекс борьбы с БПЛА коммерческого типа «Гарпун-1»</h1>
                        <img src={garpun_1_0} alt='garpun'/>
                        <p>Изделие «Гарпун-1» предназначено для создания пространственных зон безо­пасности от беспилотных летательных аппаратов (КомБПЛА) коммерческого типа, путем прицельно-пространствен­ной постановки помех в диапазонах ча­стот каналов управления и навигации КомБПЛА (GPS/ГЛОНACC/Beidou), ли­шая возможности определения свое­го местоположения и получения команд управления от оператора.</p>
                        <p>Возможен стационарный вариант испол нения при котором комплекс будет уста­новлен на двухосевой ротатор с внеш ним пультом управления.</p>
                        <h2>Изделие обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Активацию комплекса нажатием на спусковой крючок;</p>
                            <p data-number="2">Выбор режима помехового воздействия на КомБПЛА;</p>
                            <p data-number="3">Постановку помех в диапазонах частот: 2400-2483,5 МГц и 1570-1620 МГц;</p>
                            <p data-number="4">Количество блокируемых диапазонов - 2;</p>
                            <p data-number="5">Контроль уровня заряда аккумуляторной батареи;</p>
                            <p data-number="6">Продолжительность непрерывной работы до 4-х часовот встроенной сменной АКБ;</p>
                            <p data-number="7">Дальность действия до 350 метров.</p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное;</p>
                            <p data-number="2">Стационарное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от - 20°С до + 45°С</p>
                        </div>
                        <h2>Электропитание:</h2>
                        <p>От встроенной сменной АКБ в мобильном исполнении. Потребляемая мощность не более 50 Вт.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Garpun1;
