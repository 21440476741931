import React from 'react';
import './Contacts.css';

const Contacts = () => {
    return (
        <div className='contacts-all'>
            <div className='contacts-content'>
                <div className='company'>
                    <div className='company-logo'>

                    </div>
                    <div className='interactive'>
                        <p>
                        Мы предоставляем высококачественные решения в области видеонаблюдения, 
                        охранных систем и контроля доступа, обеспечивая безопасность и спокойствие наших клиентов.
                        </p>
                    </div>
                    <div className='links'>
                        <a href="https://www.facebook.com/AskomMET" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                            <i className="fab fa-facebook-f"></i>
                        </a>
                        {/* <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                            <i className="fab fa-linkedin-in"></i>
                        </a> */}
                        <a href="https://www.instagram.com/askommet/" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                            <i className="fab fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div className='contact-h2'>
                    <div className='contacts-item'>
                        <h2>Контакты</h2>
                        <div className='address'>
                            <h3>АДРЕС:</h3>
                            <p>г. Астана, ул. Бауыржан Момышулы 12, офис 210 </p>
                        </div>
                        <div className='whatsapp'>
                            <h3>WHATSAPP:</h3>
                            <p>+7 701 357 82 01</p>
                        </div>
                        <div className='email'>
                            <h3>ПОЧТА:</h3>
                            <p>info@askommet.kz</p>
                        </div>
                        <div className='phone'>
                            <h3>ТЕЛЕФОН:</h3>
                            <p>+7 (7172) 532 707</p>
                            <p>+7 701 357 82 01</p>
                        </div>
                    </div>
                </div>
                
            </div>
            <div className='copyright'>
                    <p>© 2018-2024 Все права защищены. Перепечатка и цитирование материалов запрещены.</p>
                    <p>По всем вопросам пишите на info@askommet.kz</p>
                </div>
        </div>
    );
};

export default Contacts;
