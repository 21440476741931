import React from 'react';
import sled_5 from '../../images/services-images/sled_5.jpg'
const Sled5 = () => {

    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Cистема обнаружения БПЛА коммерческого типа «След-5»</h1>
                        <img src={sled_5} alt='sled5' />
                        <p>СО «След-5» предназначена для автома­тического обнаружения и идентифика­ции радиосигналов беспилотных лета­тельных аппаратов коммерческого типа (КомБЛА), автоматической активации блоков радиоэлектронного подавления (РЭП) «Купол», а также передачи сигнала тревоги на пост ох раны. Система являет­ся полностью автоматизированной и не требует участия оператора.</p>
                        <p>Для формирования больших зон без­опасности от КомБПЛА производится развертывание нескольких устройств на территории объекта с объединением в единую локальную вычислительную сеть и, в случае необходимости, организаци­ей централизованного управления с ав­томатизированного рабочего места опе­ратора.</p>
                        <h2>СО «След-5 » обеспечивает:</h2>
                        <div className='spisok'>
                            <p data-number="1">Обнаружение радиосигналов канала управления КомБПЛА (радиолиния Пульт управления - КомБПЛА) ;</p>
                            <p data-number="2">Обнаружение радиосигналов передачи данных КомБПЛА (радиолиния КомБПЛА - Пульт управления).</p>
                            <p data-number="3">Обнаружение радиоканалов КомБПЛА осуществляется в основных используемых на сегодняшний день диапазонах частот : 433МГц/868МГц/ 900МГц/2,4ГГц / 5,8ГГц;</p>
                            <p data-number="4">Определение идентификационного номера КомБПЛА (при передаче по каналу управления в открытом виде).</p>
                        </div>
                        <h2>Система состоит из:</h2>
                        <p>АПО и ПУИСТ объедин яются в единую локальную вычислительную сеть (ЛВС). Для обеспечения возможности блокирования сигналов управления и навигации КомБП­ЛА, к Л ВС могут быть подключены блоки РЭП «Купол». Совместное использование системы обнаружения «След» и РЭП «Купол» позволяет осуществлять автоматиче­скую активацию части или всех блоков РЭП при обнаружении КомБПЛА-наруши- теля.</p>
                        <div className='spisok'>
                            <p data-number="1">Одного и более автоматических постов охраны (АПО);</p>
                            <p data-number="2">Пульта управления и сигнализации тревоги (ПУИСТ).</p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Мобильное (в кейсе с АКБ);</p>
                            <p data-number="2">Стационарное;</p>
                            <p data-number="3">Всепогодное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от + 5°С до + 45°С (кроме всепогодного исполнения);</p>
                            <p data-number="2">от - 40°С до + 45°С (всепогодное исполнение).</p>
                        </div>
                        <h2>Электропитание:</h2>
                        <p>Осуществляется от однофазной промышленной сети напряжением 220 В и частотой 50 Гц и дополнительно от встроенной сменной АКБ в мобильном исполнении. Потребляемая мощность не более 150 Вт (250 Вт - всепогодное исполнение).</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sled5;
