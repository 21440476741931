import React, { useEffect, useState, useCallback } from "react";
import { FaEnvelope } from "react-icons/fa";
import { useLocation, useParams } from "react-router-dom"; // Для отслеживания маршрутов и параметров
import './ContactButton.css';

const ContactButton = ({ onOpen }) => {
    const [isVisible, setIsVisible] = useState(false); // Кнопка по умолчанию скрыта
    const location = useLocation(); // Получаем текущий маршрут
    const { id } = useParams(); // Получаем параметр id из URL

    // Логика показа/скрытия кнопки при прокрутке страницы
    const handleScroll = useCallback(() => {
        if (location.pathname === "/") { 
            setIsVisible(window.scrollY > 300); // Появляется только после прокрутки 300px
        } else if (location.pathname.startsWith("/vacancy/") && !id) {
            setIsVisible(false); // Скрыта на странице вакансии без id
        } else {
            setIsVisible(true); // На других страницах всегда видна
        }
    }, [location.pathname, id]);

    // Обработчик прокрутки
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll); // Очистка обработчика
        };
    }, [handleScroll]);

    // Установка состояния при загрузке страницы
    useEffect(() => {
        if (location.pathname.startsWith("/vacancy/") && id) {
            setIsVisible(false); // Если это страница вакансии с id, скрыть кнопку
        } else if (location.pathname === "/") {
            setIsVisible(false); // На главной странице кнопка изначально скрыта
        } else {
            setIsVisible(true); // На других страницах видна
        }
    }, [location.pathname, id]);

    return (
        <button
            className={`contact-button ${isVisible ? "show" : ""}`}
            onClick={onOpen}
        >
            <FaEnvelope />
        </button>
    );
};

export default ContactButton;
