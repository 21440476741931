import React from 'react';
import kupol_7_0 from '../../images/services-images/kupol_7_0.jpg'
const Kupol7 = () => {
    
    return (
        <>
            <div className="antiterror">
                <div className='antiterror-content'>
                    <div className='aboutus-header'>
                        <h1>Комплекс борьбы с БПЛА коммерческого типа «Купол-7»</h1>
                        <img src={kupol_7_0} alt='kupol7'/>
                        <p>Изделие «Купол-7» предназначено для создания пространственных зон безопасности от беспилотных летательных аппаратов (КомБПЛА) коммерческого типа, путем постановки помех во всех известных диапазонах частот каналов управления и навигации КомБПЛА (GPS /ГЛОНАСС/Galileo/Beidoи),лишая возможности определения своего местоположения и получения команд управления от оператора.</p>
                        <p>Для формирования больших зон безопасности от КомБПЛА производится развертывание нескольких устройств на территории объекта с объединением в единую локальную вычислительную сеть и, в случае необходимости, организацией централизованного управления с автоматизированного рабочего места оператора.</p>
                        <div className='spisok'>
                            <p data-number="1">Максимальную выходную мощность помехового сигнала в каждом диапазоне - не менее 5 Вт;</p>
                            <p data-number="2">Постановку помех в диапазонах частот: 433 / 868 / 900 / 1200/ 1600 / 2400 / 5800 МГц;</p>
                            <p data-number="3">Максимальное количество блокируемых диапазонов - 7;</p>
                            <p data-number="4">Автономный запуск и работу без ЭВМ;</p>
                            <p data-number="5">Глубину дискретной регулировки уровня выходного сигнала - 10 дБ;</p>
                            <p data-number="6">Выбор режима помехового воздействия на КомБПЛА;</p>
                            <p data-number="7">Управление режимом функционирования изделия с автоматизированного ра­бочего места оператора или на панели устройства;</p>
                            <p data-number="8">Круглосуточный режим работы;</p>
                            <p data-number="9">Радиус действия до 1000 метров.</p>
                            <p data-number="10">Многоканальный РЭП «Купол-11» построен по модульному принципу с возможно-стью дальнейшей модернизации аппаратной и программной части.</p>
                        </div>
                        <h2>Варианты конструктивного исполнения:</h2>
                        <div className='spisok'>
                            <p data-number="1">Стационарное;</p>
                            <p data-number="2">Всепогодное.</p>
                        </div>
                        <h2>Диапазон рабочих температур:</h2>
                        <div className='spisok'>
                            <p data-number="1">от + 5°С до + 45°С (кроме всепогодного исполнения);</p>
                            <p data-number="2">от - 40°С до + 45°С (всепогодное исполнение).</p>
                        </div>
                        <h2>Электропитание:</h2>
                        <p>Осуществляется от однофазной промышленной сети напряжением 220 В и частотой 50 Гц.</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Kupol7;
