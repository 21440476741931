import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Preloader from "./components/Preloader/Preloader";
import Partners from "./components/Partners/Partners";
import News from "./components/News/News";
import Vacancies from "./components/Vacancies/Vacancies";
import Contacts from "./components/Contacts/Contacts";
import BackToTop from "./components/BackToTop/BackToTop";
import ContactButton from "./components/ContactButton/ContactButton";
import AboutUsPage from "./pages/About/AboutPage";

import VacancyDetail from "./pages/VacancyDetail/VacancyDetail";

// ANTITERROR
import Antiterror from "./pages/ServicesPage/Antiterror";
import Persei from "./pages/Antiterror/Persei";
import Navbar from "./components/Navbar/Navbar";
import Vorota from "./pages/Antiterror/Vorota"
import Barriers from "./pages/Antiterror/Barriers"
import Ptu from "./pages/Antiterror/Ptu"
import Blocker from "./pages/Antiterror/Blocker";

// ANTI-BPLA
import Antidrone from "./pages/ServicesPage/Antidrone";
import Sled1 from "./pages/Antidrone/Sled1";
import Sled5 from "./pages/Antidrone/Sled5";
import Kupol from "./pages/Antidrone/Kupol";
import Kupol7 from "./pages/Antidrone/Kupol7";
import Garpun1 from "./pages/Antidrone/Garpun1";
import Garpun2 from "./pages/Antidrone/Garpun2";

//InformSec
import InformSec from "./pages/ServicesPage/InformSec";
import Dallaslock from "./pages/InformSec/Dallaslock";
import Octopus from "./pages/InformSec/Octopus";
import Usj01 from "./pages/InformSec/Usj-01";
import Usj02disc from "./pages/InformSec/Usj-02-disc";

//Forensic
import Forensic from "./pages/ServicesPage/Forensic";

//STS
import STS from "./pages/ServicesPage/STS";

//IntegratedSecuritySystem
import IntegratedSecuritySystem from "./pages/ServicesPage/IntegratedSecuritySystem";
import Elektricheskiyeseti from "./pages/IntegratedSecuritySystem/Elektricheskiye-seti";
import IntellektualnoyeZdaniye from "./pages/IntegratedSecuritySystem/Intellektualnoye-zdaniye";
import Kompyuternyyeseti from "./pages/IntegratedSecuritySystem/Kompyuternyye-seti";
import Okhrannayasignalizatsiya from "./pages/IntegratedSecuritySystem/Okhrannaya-signalizatsiya";
import Pozharnayasignalizatsiya from "./pages/IntegratedSecuritySystem/Pozharnaya-signalizatsiya";
import Sistemaumnyydom from "./pages/IntegratedSecuritySystem/Sistema-umnyy-dom";
import Skud from "./pages/IntegratedSecuritySystem/Skud";
import Telefonnyyekommunikatsii from "./pages/IntegratedSecuritySystem/Telefonnyye-kommunikatsii";
import Videonablyudeniye from "./pages/IntegratedSecuritySystem/Videonablyudeniye";
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';

const App = () => {
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [isSending, setIsSending] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        setIsSending(true);  // Включаем индикатор отправки

        emailjs.sendForm('service_fd9rb2w', 'template_bprwxqd', e.target, 'vajjZgIrn84IeRxz2')
            .then((result) => {
                console.log('Email sent successfully', result.text);

                // Показать успешное уведомление
                Swal.fire({
                    title: 'Успех!',
                    text: 'Ваше сообщение успешно отправлено.',
                    icon: 'success',
                    confirmButtonText: 'Ок',
                    customClass: {
                        confirmButton: 'custom-alert-button',
                    },
                }).then(() => {
                    setIsSending(false);  // Отключаем индикатор отправки
                    closeModal();
                });
            }, (error) => {
                console.log('Error sending email', error.text);

                // Показать ошибку
                Swal.fire({
                    title: 'Ошибка!',
                    text: 'Не удалось отправить сообщение. Попробуйте позже.',
                    icon: 'error',
                    confirmButtonText: 'Ок',
                    customClass: {
                        confirmButton: 'custom-alert-button',
                    },
                }).then(() => {
                    setIsSending(false);  // Отключаем индикатор отправки
                });
            });
            const closeModal = () => {
                setOpen(false);  // Close the modal by setting `open` to false
            };
    };
    return (
        <Router>
            <div className="App">
                {loading ? (
                    <Preloader />
                ) : (
                    <>
                        <Header />
                        <ContactButton onOpen={handleOpen} />
                        {open && (
                            <div className="modal modal-enter">
                                <div className="modal-content modal-enter-active">
                                    <div className="modal-header">
                                        <h2>Связаться с нами</h2>
                                        <button className="close-button" onClick={handleClose}>×</button>
                                    </div>
                                    <form onSubmit={sendEmail}>
                                        <input type="text" name="name_from" placeholder="Ваше имя" required />
                                        <input type="email" name="email_from" placeholder="Ваш email" required />
                                        <input type="tel" name="phone_from" placeholder="Ваш телефон" required />
                                        <div className='input-text-modal'>
                                            <textarea name="message" placeholder="Комментарий"></textarea>
                                        </div>
                                        <input
                                            type="submit"
                                            value={isSending ? "Отправка..." : "Отправить"}
                                            disabled={isSending}  // Блокируем кнопку при отправке
                                        />
                                    </form>
                                    
                                    <p className="disclaimer">
                                        Нажав 'Отправить', вы соглашаетесь с обработкой вашей информации в соответствии с нашей Политикой конфиденциальности.
                                    </p>
                                </div>
                            </div>
                        )}
                        <Routes>
                            <Route path="/" element={
                                <>
                                    <Home onOpenModal={handleOpen} />
                                    <div id="about">
                                        <About />
                                    </div>
                                    <div id="services">
                                        <Services />
                                    </div>
                                    <div id="partners">
                                        <Partners />
                                    </div>
                                    <div id="news">
                                        <News />
                                    </div>
                                    <div id="vacancies">
                                        <Vacancies />
                                    </div>
                                    <div id="contacts">
                                        <Contacts />
                                    </div>
                                    <BackToTop />
                                </>
                            } />
                            <Route path="/vacancy/:id" element={<VacancyDetail />} />
                            <Route path="/aboutus" element={<AboutUsPage />} />

                            <Route path="/integratedsecuritysystem" element={
                                <>
                                    <Navbar />
                                    <IntegratedSecuritySystem />
                                </>
                            } />

                            <Route path="/integratedsecuritysystem/elektricheskiye-seti" element={
                                <>
                                    <Navbar />
                                    <Elektricheskiyeseti />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/intellektualnoye-zdaniye" element={
                                <>
                                    <Navbar />
                                    <IntellektualnoyeZdaniye />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/kompyuternyye-seti" element={
                                <>
                                    <Navbar />
                                    <Kompyuternyyeseti />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/okhrannaya-signalizatsiya" element={
                                <>
                                    <Navbar />
                                    <Okhrannayasignalizatsiya />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/pozharnaya-signalizatsiya" element={
                                <>
                                    <Navbar />
                                    <Pozharnayasignalizatsiya />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/sistema-umnyy-dom" element={
                                <>
                                    <Navbar />
                                    <Sistemaumnyydom />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/skud" element={
                                <>
                                    <Navbar />
                                    <Skud />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/telefonnyye-kommunikatsii" element={
                                <>
                                    <Navbar />
                                    <Telefonnyyekommunikatsii />
                                </>
                            } />
                            <Route path="/integratedsecuritysystem/videonablyudeniye" element={
                                <>
                                    <Navbar />
                                    <Videonablyudeniye />
                                </>
                            } />

                            <Route path="/antiterror" element={
                                <>
                                    <Navbar />
                                    <Antiterror />
                                </>
                            } />
                            <Route path="/antiterror/persei-17" element={
                                <>
                                    <Navbar />
                                    <Persei />
                                </>
                            } />
                            <Route path="/antiterror/vorota" element={
                                <>
                                    <Navbar />
                                    <Vorota />
                                </>
                            } />
                            <Route path="/antiterror/barriers" element={
                                <>
                                    <Navbar />
                                    <Barriers />
                                </>
                            } />
                            <Route path="/antiterror/ptu" element={
                                <>
                                    <Navbar />
                                    <Ptu />
                                </>
                            } />
                            <Route path="/antiterror/blocker" element={
                                <>
                                    <Navbar />
                                    <Blocker />
                                </>
                            } />
                            <Route path="/antidrone" element={
                                <>
                                    <Navbar />
                                    <Antidrone />
                                </>
                            } />
                            <Route path="/antidrone/sled-1" element={
                                <>
                                    <Navbar />
                                    <Sled1 />
                                </>
                            } />
                            <Route path="/antidrone/sled-5" element={
                                <>
                                    <Navbar />
                                    <Sled5 />
                                </>
                            } />
                            <Route path="/antidrone/kupol" element={
                                <>
                                    <Navbar />
                                    <Kupol />
                                </>
                            } />
                            <Route path="/antidrone/kupol-7" element={
                                <>
                                    <Navbar />
                                    <Kupol7 />
                                </>
                            } />
                            <Route path="/antidrone/garpun-1" element={
                                <>
                                    <Navbar />
                                    <Garpun1 />
                                </>
                            } />
                            <Route path="/antidrone/garpun-2" element={
                                <>
                                    <Navbar />
                                    <Garpun2 />
                                </>
                            } />


                            <Route path="/informsec" element={
                                <>
                                    <Navbar />
                                    <InformSec />
                                </>
                            } />
                            <Route path="/informsec/dallaslock" element={
                                <>
                                    <Navbar />
                                    <Dallaslock />
                                </>
                            } />
                            <Route path="/informsec/octopus" element={
                                <>
                                    <Navbar />
                                    <Octopus />
                                </>
                            } />
                            <Route path="/informsec/usj-01" element={
                                <>
                                    <Navbar />
                                    <Usj01 />
                                </>
                            } />
                            <Route path="/informsec/usj-02-disc" element={
                                <>
                                    <Navbar />
                                    <Usj02disc />
                                </>
                            } />
                            <Route path="/forensic" element={
                                <>
                                    <Navbar />
                                    <Forensic />
                                </>
                            } />
                            <Route path="/sts" element={
                                <>
                                    <Navbar />
                                    <STS />
                                </>
                            } />
                        </Routes>
                    </>
                )}
            </div>
        </Router>
    );
};

export default App;
